// Opaque colors
$transparent-white: rgba(255, 255, 255, 0);
$transparent-black: rgba(0, 0, 0, 0);

// Neutral colors
$black: #000000;
$dark-gray: #1E1E1E;
// $dark-gray: #252830;
$charcoal-gray: #464646;
$elephant-gray: #676767;
$silver: #C0C0C0;
$ash-white: #F8F9Fa;
$white: #FFFFFF;

// Pastel colors
$pastel-green: #53BB7E;
$pastel-blue: #1DA1F3;
$pastel-red: #FF5253;
$pastel-yellow: #FDFD7D;

// Primary colors
$blue: #1997C6;
$green: #1BC98E;
$red: #E64759;
$yellow: #E4D836;
$purple: #9F86FF;

$navy-blue: #004080;
$coral: #C86464;
$lavendar: #6464C8;
