@import "../../styles/global";

$active-color: $blue;
$left-padding: 15px;
$spacing: 10px;

.navBar {
    background-color: $dark-gray;
    padding: $left-padding;
}

.navBarWrapper {
    position: relative;
    display: inline-block;
}

.underBar {
    width: 0;
    height: 5px;
    left: 0;
    background: $transparent-white;
    top: 25px;
    position: absolute;
    -webkit-transition: 0.5s ease;
}

// Including in here to achieve floating underline
// Source: https://codepen.io/ShayStibelman/pen/reJLy
.link {
    color: $ash-white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    transition: color 0.5s ease;
    -webkit-transition: 0.5s ease;
    outline: none;

    &:not(:first-of-type) {
        margin-left: $spacing;
    }
}

.active {
    color: $active-color;
}

.socialButtons {
    float: right;
    margin-top: -7px;
}

.socialButton {
    display: inline-block;

    &:not(:first-of-type) {
        margin-left: 8px;
    }
}
