$mobile: 0;
$tablet: 760px;
$laptop: 1020px;
$desktop: 1440px;
$infinity-breakpoint: 1000000px;

$mobile-content-width: 100%;
// $tablet-content-width: 100%;
$tablet-content-width: 700px;
$laptop-content-width: 1000px;
$desktop-content-width: 1200px;

$breakpoints: (
    mobile: $mobile,
    tablet: $tablet,
    laptop: $laptop,
    desktop: $desktop  
);

$next-breakpoints: (
    mobile: $tablet,
    tablet: $laptop,
    laptop: $desktop,
    desktop: $infinity-breakpoint  
);

@function up-to($bp) {
    @return $bp - 1px;
}

@function get-breakpoint($breakpoint-map, $bp) {
    @if map-has-key($breakpoint-map, $bp) {
        @return map-get($breakpoint-map, $bp)
    }
     
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$bp}`. Available breakpoints are: #{map-keys($breakpoint-map)}.";
    }
}

@mixin breakpoint-thinner-than($bp) {
    @media screen and (max-width: up-to(get-breakpoint($breakpoints, $bp))) {
        @content;
    }
}

@mixin breakpoint-wider-than($bp) {
    @media screen and (min-width: get-breakpoint($breakpoints, $bp)) {
        @content;
    }
}

@mixin breakpoint($bp) {
    @media screen and (min-width: get-breakpoint($breakpoints, $bp)) and (max-width: up-to(get-breakpoint($next-breakpoints, $bp))) {
        @content;
    }
}

@mixin breakpoint-content-wrapper() {
    margin: auto;
    
    @include breakpoint(desktop) {
        width: $desktop-content-width;
    }
    
    @include breakpoint(laptop) {
        width: $laptop-content-width;
    }
    
    @include breakpoint(tablet) {
        width: $tablet-content-width;
    }
    
    @include breakpoint(mobile) {
        width: $mobile-content-width;
    }
}
