@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import "colors";
@import "breakpoints";
@import "mixins";

body {
    // @include gradient($navy-blue, #363795);

    //TODO
    background: #141E30;  /* fallback for old browsers */
    background: -webkit-linear-gradient(#141E30, #243B55);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(#141E30, #243B55); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    background-attachment: fixed;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    height: 100vh;
}
