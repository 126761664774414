@mixin gradient($startColor, $endColor) {
    background: $startColor;
    background: -moz-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: -webkit-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: linear-gradient(to bottom, $startColor 0%, $endColor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{ $startColor }, endColorstr=#{ $endColor }, GradientType=0 );
}

@mixin floatingEvent() {
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: $ash-white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.08);
}
